<template>
    <div>
        <div class="right-menu shipping-right">
            
            <subHeader pageName="GROUPS" />

            <tutorialsAction moduleName="GROUPS"/>

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <moduleSearch :moduleName="'group'" :disable="tourIsActive" ref="module_search"/>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="groupsTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == groupsList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != ''"
                                                    />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Name">Name</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Description">Description</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Contacts">Contacts</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="groupsLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="17"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </label>
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td style="position: relative;">
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="95"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="groupsList && groupsList.length == 0">
                                        <td colspan="5" style="text-align:center">
                                            <p class="mb-0">
                                                {{ groupsSearchKeyword ? `No groups found matching '${groupsSearchKeyword}'` : 'No Groups Available' }}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr v-for="(group, index) in groupsList" :key="index">
                                        
                                        <!-- checkbox block starts here -->
                                        <td @click="previewGroup(group,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <div style="width: 100%;" data-open="true">
                                                    <div class="ctmCheckWrap" data-open="true">
                                                        <label class="ctmTableCheckContainer" data-open="false" @click.prevent=" group.id != -1 ? checkedItemHandle(group.id) : ''">
                                                            <input type="checkbox" v-model="checkedItems" :value="group.id" :disabled="tourIsActive || group.id == -1" data-open="false">
                                                            <span class="checkmark" data-open="false"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- checkbox block ends here -->

                                        <!-- name block starts here -->
                                        <td @click.prevent="previewGroup(group,index,'input',$event)">
                                            <div class="editFieldTitle customerNameField" data-open="true" id="table-name-field">
                                                <input 
                                                    type="text"
                                                    placeholder="Write a group name"
                                                    v-model="group.name" 
                                                    @focus="groupSelection(group)" 
                                                    @blur="handleOTGUpdate(group,index)"
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- name block ends here -->

                                        <!-- description block starts here -->
                                        <td @click.prevent="previewGroup(group,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ 
                                                        group.description ? group.description.length > 80 ? 
                                                        group.description.substr(0,80) + '...' : group.description : '--' 
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- description block ends here -->

                                        <!-- contacts block starts here -->
                                        <td @click="previewGroup(group,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" data-open="true">
                                                <el-tooltip class="box-item" effect="dark" :content="group.contacts ? group.contacts.length == 1 ? `${group.contacts.length} Contact` : `${group.contacts.length} Contacts` : '' " placement="right">
                                                    <button class="delFunction view" @click.prevent="openGroupedContactsModal(group,index)" :disabled="tourIsActive" data-open="false">
                                                        <svg baseProfile="tiny" height="24px" id="Layer_1" version="1.2" viewBox="0 0 24 24" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                            <g>
                                                                <path d="M12,14c1.381,0,2.631-0.56,3.536-1.465C16.44,11.631,17,10.381,17,9s-0.56-2.631-1.464-3.535C14.631,4.56,13.381,4,12,4   S9.369,4.56,8.464,5.465C7.56,6.369,7,7.619,7,9s0.56,2.631,1.464,3.535C9.369,13.44,10.619,14,12,14z"/>
                                                                <path d="M20,15c0.69,0,1.315-0.279,1.768-0.731c0.453-0.452,0.732-1.077,0.732-1.769c0-0.69-0.279-1.315-0.732-1.768   C21.315,10.279,20.69,10,20,10c-0.691,0-1.316,0.279-1.769,0.732C17.779,11.185,17.5,11.81,17.5,12.5   c0,0.691,0.279,1.316,0.731,1.769S19.309,15,20,15z"/>
                                                                <path d="M20,15.59c-1.331,0-2.332,0.406-2.917,0.968C15.968,15.641,14.205,15,12,15c-2.266,0-3.995,0.648-5.092,1.564   C6.312,15.999,5.3,15.59,4,15.59c-2.188,0-3.5,1.09-3.5,2.182c0,0.545,1.312,1.092,3.5,1.092c0.604,0,1.146-0.051,1.623-0.133   c-0.01,0.091-0.04,0.18-0.04,0.27c0,1,2.406,2,6.417,2c3.762,0,6.417-1,6.417-2c0-0.085-0.011-0.17-0.02-0.255   c0.463,0.073,0.995,0.118,1.603,0.118c2.051,0,3.5-0.547,3.5-1.092C23.5,16.68,22.127,15.59,20,15.59z"/>
                                                                <path d="M4,15c0.69,0,1.315-0.279,1.768-0.732C6.221,13.815,6.5,13.19,6.5,12.5c0-0.689-0.279-1.314-0.732-1.768   C5.315,10.28,4.69,10,4,10c-0.691,0-1.316,0.28-1.769,0.732C1.779,11.186,1.5,11.811,1.5,12.5c0,0.69,0.279,1.315,0.731,1.768   C2.684,14.721,3.309,15,4,15z"/>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </el-tooltip>
                                            </div>
                                        </td>
                                        <!-- contacts block ends here -->

                                        <!-- action block starts here -->
                                        <td @click="previewGroup(group,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(group.id) && deleteLoader" data-open="true">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button class="delFunction" @click.prevent="removeGroupHandle(group,index)" :disabled="tourIsActive" data-open="false">
                                                    <i class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->
                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="17"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </label>
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td style="position: relative;">
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="95"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="102"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- add/update group form starts here -->
                        <transition name="record-form">
                            <div class="productSlideBox activeRow" ref="viewInputForm" v-if="previewForm.id">
                                <div class="tableActionBtns" id="group-details-preview">
                                    <div class="productTableSideBar">
                                        <div class="row">
                                            <div class="col-md-8" id="header-name-field">
                                                <div class="viewOrderTitle">
                                                    <input 
                                                        type="text" 
                                                        placeholder="Write a group name" 
                                                        v-model="previewForm.name" 
                                                        :disabled="tourIsActive"
                                                    >
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="orderViewNav">
                                                    <ul>
                                                        <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                            <li class="sm-extand-hide">
                                                                <button @click.prevent="expand" :disabled="tourIsActive">
                                                                    <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" id="expand-form">
                                                                        <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                            <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                            <path d="m16.5.522-6 5.907"/>
                                                                            <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                            <path d="m16.5 16.429-6-5.907"/>
                                                                            <path d="m.5 5.5v-5h5.5"/>
                                                                            <path d="m6.5 6.429-6-5.907"/>
                                                                            <path d="m6 16.516-5.5.007v-5.023"/>
                                                                            <path d="m6.5 10.5-6 6"/>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                            </li>
                                                        </el-tooltip>
                                                        <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                            <li>
                                                                <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                        <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                            <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                                <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                                <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                            </li>
                                                        </el-tooltip>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sectionTabsLink" style="padding: 15px 20px;">
                                        <ul onclick="window.myFunction(event)">
                                            <li v-if="!tourIsActive">
                                                <a class="active">Group Info</a>
                                            </li>
                                        </ul>
                                        <div class="updateBtnBox" v-if="groupIsSaving">
                                            <loaderBtn/>
                                        </div>
                                        <div class="updateBtnBox" id="save-group-btn" v-else>
                                            <button 
                                                form="group-information" 
                                                type="submit" 
                                                :disabled="tourIsActive"
                                            >
                                                {{ previewForm.id == -1 ? 'Save' : 'Update' }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="productTableInfoBlock">
                                    <div class="productTableInfoWhiteBox">
                                        <ValidationObserver ref="groupObserver">
                                            <form id="group-information" @submit.prevent="validate('preview')">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <ValidationProvider name="name" :rules="{required:true,max:250}" v-slot="{ errors }">
                                                            <div class="tableFromFieldBox">
                                                                <label for="name-field">Name<span>*</span></label>
                                                                <div class="tableFromFieldItem">
                                                                    <input 
                                                                        type="text" 
                                                                        name="name-field" 
                                                                        id="name-field" 
                                                                        placeholder="Name" 
                                                                        v-model="previewForm.name" 
                                                                        :disabled="tourIsActive"
                                                                    >
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <ValidationProvider name="description" :rules="{max:500}" v-slot="{ errors }">
                                                            <div class="tableFromFieldBox">
                                                                <label for="description-field">Description</label>
                                                                <div class="tableFromFieldItem">
                                                                    <textarea
                                                                        class="form-control foam-border comment-box mt-0"
                                                                        placeholder="Description"
                                                                        id="description-field"
                                                                        name="description-field"
                                                                        rows="3"
                                                                        cols="30"
                                                                        v-model="previewForm.description"
                                                                        :disabled="tourIsActive"
                                                                    ></textarea>
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </form>
                                        </ValidationObserver>
                                    </div>
                                    <div class="productTableInfoWhiteBox" id="contacts-table">
                                        <div class="productTableInfoWhiteBoxTitle groups">
                                            <h5>Contacts</h5>
                                        </div>
                                        <div class="addOrderTable">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Full Name</th>
                                                        <th>Email</th>
                                                        <th>Phone</th>
                                                        <th>City</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="previewForm.contacts.length == 0">
                                                        <td colspan="6">
                                                            The group has no contacts at the moment
                                                        </td>
                                                    </tr>
                                                    <tr v-for="(contact, index) in previewForm.contacts" :key="`contact-${index}`">
                                                        <td>
                                                            <div class="productOrderTableTxt">
                                                                <p>{{ contact.first_name ? contact.last_name ? contact.first_name + " " + contact.last_name : '--' : '--' }}</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="productOrderTableTxt">
                                                                <p>{{ contact.email }}</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="productOrderTableTxt">
                                                                <p>{{ contact.mobile_no ? contact.mobile_no : '--' }}</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="productOrderTableTxt">
                                                                <p>{{ contact.city ? contact.city.name : '--' }}</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="ctmDataTableActionBtn">
                                                                <button class="delFunction" @click="removeFormContactHandle(contact,index)">
                                                                    <i class="fa fa-trash-o"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <!-- <hr>

                                            <el-pagination
                                                background
                                                layout="prev, pager, next"
                                                :page-count="contactsPagination.total"
                                                @current-change="contactsPaginateChangeHandle"
                                                class="mt-4"
                                            />

                                            <hr> -->

                                        </div>
                                        <div class="orderAddProductBtn">
                                            <button 
                                                id="contacts-add-btn"
                                                @click.prevent="openContactsModal()"
                                                type="button"
                                                :disabled="tourIsActive"
                                            >
                                                + Add Contacts
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- add/update group form ends here -->

                    </div>
                    <!-- data table ends here -->

                    <!-- bottom action bar starts here-->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != groupsList.length">
                                    <i class="fa fa-check-square-o"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 ml-4" @click.prevent="deleteBulkGroups()">
                                    <i class="fa fa-trash-o"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- bottom action bar ends here-->

                </div> 
            </div>

            <!-- all contacts modal starts here -->
            <transition name="modal">
                <div class="modalBg modal-active" v-if="contactsModal.isOpen">
                    <div class="modalLayers" @click="closeContactsModal()"></div>
                    <div class="modalBody">
                        <div class="addProductTitle">
                            <h3>Contacts</h3>
                        </div>
                        <div class="searchProductForm" style="margin-bottom:0px !important">
                            <input
                                type="search"
                                name="searchContact"
                                id="searchContact"
                                placeholder="Search contact"
                                v-model="searchedContact"
                                @input="searchContacts('search',1)"
                                style="width: 70%;"
                            />
                            <select 
                                v-model="selectedContactFilter"
                                @change="searchContacts('search',1)"
                            >
                                <option value="all">All</option>
                                <option value="subscribed">Subscribed</option>
                                <option value="unsubscribed">Unsubscribed</option>
                            </select>
                        </div>
                        <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px; overflow: auto;">
                            <div id="London" class="tabcontent">
                                <div class="content-table">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div class="ctmCheckWrap">
                                                        <label class="ctmTableCheckContainer">
                                                            <input 
                                                                type="checkbox"
                                                                @click="contactsToggleIsCheckedAll()"
                                                                :checked="checkSelectedContacts()"
                                                            />
                                                            <span class="checkmark" style="width: 19px; height:18px"></span>
                                                        </label>
                                                        <div class="ctmTableImgWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Contact Image">Image</p>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="ctmCheckWrap">
                                                        <div class="ctmTableImgWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Contact First Name">
                                                                First Name
                                                            </p>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="ctmCheckWrap">
                                                        <div class="ctmTableImgWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Contact Last Name">
                                                                Last Name
                                                            </p>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="ctmCheckWrap">
                                                        <div class="ctmTableImgWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Contact Email">
                                                                Email
                                                            </p>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="ctmCheckWrap">
                                                        <div class="ctmTableImgWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Contact Phone">
                                                                Phone
                                                            </p>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="ctmCheckWrap">
                                                        <div class="ctmTableImgWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Contact City">
                                                                City
                                                            </p>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="ctmCheckWrap">
                                                        <div class="ctmTableImgWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Subscribed">
                                                                Subscribed
                                                            </p>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="contactsSearchLoader">
                                            <tr v-for="i in 5" :key="`contact-load-${i}`">
                                                <td>
                                                    <div class="ctmCheckWrap">
                                                        <vue-skeleton-loader
                                                            type="rect"
                                                            :width="20"
                                                            :height="20"
                                                            animation="fade"
                                                        />
                                                        <div class="ctmDataTableImgBox">
                                                            <vue-skeleton-loader
                                                                type="circle"
                                                                :width="36"
                                                                :height="36"
                                                                animation="fade"
                                                            />
                                                        </div> 
                                                    </div>
                                                </td>
                                                <td class="mainTableTitle">
                                                    <div class="editFieldTitle">
                                                        <vue-skeleton-loader
                                                            type="rect"
                                                            :width="100"
                                                            :height="20"
                                                            animation="fade"
                                                        />
                                                    </div>
                                                </td>
                                                <td class="mainTableTitle">
                                                    <div class="editFieldTitle">
                                                        <vue-skeleton-loader
                                                            type="rect"
                                                            :width="100"
                                                            :height="20"
                                                            animation="fade"
                                                        />
                                                    </div>
                                                </td>
                                                <td class="mainTableTitle">
                                                    <div class="editFieldTitle">
                                                        <vue-skeleton-loader
                                                            type="rect"
                                                            :width="100"
                                                            :height="20"
                                                            animation="fade"
                                                        />
                                                    </div>
                                                </td>
                                                <td class="mainTableTitle">
                                                    <div class="editFieldTitle">
                                                        <vue-skeleton-loader
                                                            type="rect"
                                                            :width="100"
                                                            :height="20"
                                                            animation="fade"
                                                        />
                                                    </div>
                                                </td>
                                                <td class="mainTableTitle">
                                                    <div class="editFieldTitle">
                                                        <vue-skeleton-loader
                                                            type="rect"
                                                            :width="100"
                                                            :height="20"
                                                            animation="fade"
                                                        />
                                                    </div>
                                                </td>
                                                <td class="mainTableTitle">
                                                    <div class="editFieldTitle">
                                                        <vue-skeleton-loader
                                                            type="rect"
                                                            :width="100"
                                                            :height="20"
                                                            animation="fade"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr v-if="contactsModal.list.length == 0">
                                                <td colspan="7" style="text-align:center"><p class="mb-0">No Contacts Available</p></td>
                                            </tr>
                                            <tr v-for="(listedContact,index) in contactsModal.list" :key="`listedContact-${index}`">
                                                <td>
                                                    <div class="ctmCheckWrap">
                                                        <label class="ctmTableCheckContainer">
                                                            <input 
                                                                type="checkbox"
                                                                v-model="contactsModal.selectedContacts"
                                                                :value="listedContact"
                                                            />
                                                            <span class="checkmark" style="width: 19px; height:18px"></span>
                                                        </label>
                                                        <div class="ctmDataTableImgBox">
                                                            <img
                                                                :src="generateContactImageSrc(listedContact) ? generateContactImageSrc(listedContact) : '/images/customer_noImage.png'"
                                                                alt=""
                                                                data-open="true"
                                                                @error="handleImageError"
                                                            >
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{ listedContact.first_name ? listedContact.first_name : '--' }}</td>
                                                <td>{{ listedContact.last_name ? listedContact.last_name : '--' }}</td>
                                                <td>{{ listedContact.email ? listedContact.email : '--' }}</td>
                                                <td>{{ listedContact.mobile_no ? listedContact.mobile_no : '--' }}</td>
                                                <td>{{ listedContact.city ? listedContact.city.name : '--' }}</td>
                                                <td>
                                                    <div :class="listedContact.is_subscribed == 1 ? 'valid' : 'invalid'">
                                                        <p>
                                                            {{ listedContact.is_subscribed == 1 ? 'Yes' : 'No' }}
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <hr>

                        <el-pagination
                            background
                            layout="prev, pager, next"
                            :page-count="contactsPagination.total"
                            @current-change="contactsPaginateChangeHandle"
                            class="mt-4"
                            v-if="contactsModal.isOpen"
                        />

                        <hr>

                        <div class="modalCloseIcon">
                            <button @click="closeContactsModal()">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                    <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
            <!-- all contacts modal ends here -->

            <!-- contacts modal (for data table) starts here -->
            <transition name="modal">
                <div class="modalBg modal-active" v-if="groupedContactsModal.isOpen">
                    <div class="modalLayers" @click="groupedContactsModal.isOpen = false"></div>
                    <div class="modalBody">
                        <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px;overflow: auto;">
                            <div id="London" class="tabcontent">
                                <div class="contaient-div horizontal-scroll-fix">
                                    <div class="customer-detail customer-detail-product contacts">
                                        <h2>CONTACTS</h2>
                                    </div>
                                </div>
                                <div class="content-table">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div class="ctmCheckWrap">
                                                        <div class="ctmTableImgWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Contact First Name">
                                                                First Name
                                                            </p>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="ctmCheckWrap">
                                                        <div class="ctmTableImgWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Contact Last Name">
                                                                Last Name
                                                            </p>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="ctmCheckWrap">
                                                        <div class="ctmTableImgWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Contact Email">
                                                                Email
                                                            </p>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="ctmCheckWrap">
                                                        <div class="ctmTableImgWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Contact Phone">
                                                                Phone
                                                            </p>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="ctmCheckWrap">
                                                        <div class="ctmTableImgWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Contact City">
                                                                City
                                                            </p>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="ctmCheckWrap">
                                                        <div class="ctmTableImgWrap">
                                                            <p data-toggle="tooltip" data-placement="top" title="Subscribed">
                                                                Subscribed
                                                            </p>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(listedContact,index) in groupedContactsModal.contacts" :key="`groupedContact-${index}`">
                                                
                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <p>{{ listedContact.first_name ? listedContact.first_name : '--' }}</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <p>{{ listedContact.last_name ? listedContact.last_name : '--' }}</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <p>{{ listedContact.email ? listedContact.email : '--' }}</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <p>{{ listedContact.mobile_no ? listedContact.mobile_no : '--' }}</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <p>{{ listedContact.city ? listedContact.city.name : '--' }}</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div :class="listedContact.is_subscribed == 1 ? 'valid' : 'invalid'">
                                                        <p>
                                                            {{ listedContact.is_subscribed == 1 ? 'Yes' : 'No' }}
                                                        </p>
                                                    </div>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modalCloseIcon">
                            <button @click="groupedContactsModal.isOpen = false">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                    <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
            <!-- contacts modal (for data table) ends here -->

        </div>

        <!--vue tour-->
        <v-tour name="groupsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { MessageBox, Message, Loading } from 'element-ui'
import Multiselect from "vue-multiselect";
import moduleSearch from "@/components/ModuleSearch/index.vue";

export default {
    name:'Groups',
    metaInfo() {
        return {
            title: 'CRM | Groups | YeetCommerce',
        };
    },
    data() {
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,

            checkedItems: [],
            deletingItems: [],
            deleteLoader: false,
            timer: undefined,
            isMasterSearched: false,

            //groups start
            groupsLoading: false,
            groupsList: [],
            isGroupSaved: false,
            groupIsSaving: false,
            previewForm:{
                id: '',
                isPreview: false,
                name: '',
                description: '',
                contacts: [],
                isLoading: false,
                isTouched: false,
            },
            tempGroup:{
                id: '',
                isPreview: false,
                name: '',
                description: '',
                contacts: [],
                isLoading: false,
                isTouched: false,
            },
            tempPreviewGroup:{
                id: '',
                isPreview: false,
                name: '',
                description: '',
                contacts: [],
                isLoading: false,
                isTouched: false,
            },
            //groups end

            //contacts start
            contactsModal:{
                list: [],
                selectedContacts: [],
                isOpen: false,
            },
            groupedContactsModal:{
                contacts: [],
                isOpen: false,
            },
            searchedContact: '',
            selectedContactFilter: 'all',
            contactsSearchLoader: false,
            contactsPagination:{
                current_page: 0,
                total: 0,
                previous_page: 0,
                last_page: 0,
            },
            //contacts end

            //vue-tour
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //search bar step
                {
                    target: '#module-search',
                    content: `<strong>Search Group</strong><br>It enables you to search specific groups.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                //form steps
                {
                    target: '#preview-record',
                    content: `<strong>Groups Table</strong><br>You can preview and edit the added groups by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>Group</strong><br>By clicking this button, you can add a new group and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#group-details-preview',
                    content: `<strong>Group Details Form</strong><br>This form can be used to add details about the group and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#name-field',
                    content: `<strong>Group's Name</strong><br>Name of the group can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#description-field',
                    content: `<strong>Group's Description</strong><br>Description of the group can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#contacts-table',
                    content: `<strong>Contacts Table</strong><br>Contacts added to the group can be view in this table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#contacts-add-btn',
                    content: `<strong>Add Contacts</strong><br>Contacts can be added to the group by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#table-name-field',
                    content: `<strong>Group's Name</strong><br>Name of the group can also be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#header-name-field',
                    content: `<strong>Group's Name</strong><br>Name of the group can also be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#save-group-btn',
                    content: `<strong>Save Group Details</strong><br>Added group details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
            ],
            tourIsActive: false,
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn,
        Multiselect,
        moduleSearch,
    },
    computed:{
        ...mapGetters({
            settings: 'settings_module/settings',
            allStoredContacts: 'contacts_module/allContacts',
            storedGroups: 'groups_module/groups',
            groupsCurrentPage: 'groups_module/currentPage',
            groupsLastPage: 'groups_module/lastPage',
            groupsSearchKeyword:'groups_module/keyword',
            isPending: 'groups_module/isPending',
        }),
    },
    watch:{
        'storedGroups':{
            handler:function(value){
                
                this.groupsLoading = false;
                
                if(this.previewForm.id == -1){

                    this.groupsList = _.cloneDeep(value)

                    this.groupsList.unshift(this.previewForm)

                }else if(this.tempGroup.id != ''){

                    this.groupsList = _.cloneDeep(value)

                    this.groupsList[this.previewForm.index] = this.previewForm

                }else{

                    this.groupsList = _.cloneDeep(value)

                }

                if(this.isMasterSearched){

                    this.formPreview(this.groupsList[0],0);

                    this.isMasterSearched = false
                }

            },deep:true
        },
        'allStoredContacts':{
            handler:function(value){

                this.contactsModal.list = _.cloneDeep(value)

            },deep:true
        },
        'previewForm':{
            handler:function(value){

                if((value.id == -1) && (value.name || value.description || value.contacts.length > 0 )){

                    value.isTouched = true

                }else{

                    value.isTouched = false

                }

            },
            deep:true
        },
    },
    methods:{

        handleScrollEvent(event){
            
            let element = this.$refs.groupsTable;
            
            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.groupsCurrentPage != this.groupsLastPage){

                    this.$store.dispatch('groups_module/fetchStoreGroupsRequest',{size:15 , page: this.groupsCurrentPage + 1, loadData:'more'});

                }

            }

        },

        groupSelection(group){

            this.tempGroup = _.cloneDeep(group);

        },
        handleOTGUpdate(group,i){
        
            if(this.previewForm.id == ''){

                if(group.id != -1){

                    if(this.tempGroup.name != group.name){
                        
                        let isRequired = false;

                        if(group.name.trim().length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The group's name field is required.",
                            });

                            isRequired = true;

                        }

                        if(group.name.trim().length > 250){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The group's name field may not be greater than 250 characters.",
                            });

                            isRequired = true;

                        }

                        if(isRequired){

                            this.groupsList[i].name = this.tempGroup.name;

                            this.tempGroup = {
                                id: '',
                                isPreview: false,
                                name: '',
                                description: '',
                                contacts:[],
                                isLoading: false,
                                isTouched:false,
                            }

                            return

                        }

                        MessageBox.confirm(
                            'You have unsaved changes, save and proceed?',
                            'Confirm',
                            {
                                distinguishCancelAndClose: true,
                                confirmButtonText: 'Save',
                                cancelButtonText: 'Discard Changes',
                            }
                        ).then(() => {

                            this.updateDataTableRecord(group,i);

                        }).catch(() => {

                            this.groupsList[i].name = this.tempGroup.name;

                            this.tempGroup = {
                                id: '',
                                isPreview: false,
                                name: '',
                                description: '',
                                contacts:[],
                                isLoading: false,
                                isTouched:false,
                            }

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes discarded successfully.",
                            });

                        })
                    
                    }

                }

            }else{

                if(group.id != -1){

                    if(group.id != this.previewForm.id){

                        if(this.tempGroup.name != group.name){
                            
                            let isRequired = false;

                            if(group.name.trim().length == 0){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The group's name field is required.",
                                });

                                isRequired = true;

                            }

                            if(group.name.trim().length > 250){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The group's name field may not be greater than 250 characters.",
                                });

                                isRequired = true;

                            }

                            if(isRequired){

                                if(this.groupsList[i].name.length == 0 || this.groupsList[i].name.length > 250){

                                    this.groupsList[i].name = this.tempGroup.name;

                                }

                                this.tempGroup = {
                                    id: '',
                                    isPreview: false,
                                    name: '',
                                    description: '',
                                    contacts:[],
                                    isLoading: false,
                                    isTouched:false,
                                }

                                return

                            }

                            MessageBox.confirm(
                                'You have unsaved changes, save and proceed?',
                                'Confirm',
                                {
                                    distinguishCancelAndClose: true,
                                    confirmButtonText: 'Save',
                                    cancelButtonText: 'Discard Changes',
                                }
                            ).then(() => {

                                this.updateDataTableRecord(group,i);

                            }).catch(() => {

                                this.groupsList[i].name = this.tempGroup.name;

                                this.tempGroup = {
                                    id: '',
                                    isPreview: false,
                                    name: '',
                                    description: '',
                                    contacts:[],
                                    isLoading: false,
                                    isTouched:false,
                                }

                                this.$notify({
                                    type: "info",
                                    title: "Changes Discarded",
                                    message: "Changes discarded successfully.",
                                });

                            })
                        
                        }

                    }

                }

            }

        },
        async updateDataTableRecord(manipulatedRecord,i){

            let formData = new FormData();

            formData.append('field','name');
            formData.append('group_id',manipulatedRecord.id);

            formData.append('name',manipulatedRecord.name);

            let loader = Loading.service({
                text: "The group is being updated. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.post('/crm/group/selective/update',formData);
                if(res.data.status_code == "1442"){
                    
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.groupsList[i] = _.cloneDeep(res.data.group);

                    let payload = {
                        index: i,
                        updatedGroup: res.data.group
                    }

                    this.$store.commit('groups_module/update_groups_list',payload);

                    this.tempGroup = {
                        id: '',
                        isPreview: false,
                        name: '',
                        description: '',
                        contacts:[],
                        isLoading: false,
                        isTouched:false,
                    }

                }

            }catch(error){

                this.groupsList[i].name = this.tempGroup.name;

                this.tempGroup = {
                    id: '',
                    isPreview: false,
                    name: '',
                    description: '',
                    contacts:[],
                    isLoading: false,
                    isTouched:false,
                }

                if(error.response){

                    if(error.response.data.error.group_id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.group_id[0],
                        });

                    }else if(error.response.data.error.name){
                        
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.name[0],
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                loader.close();

            }

        },
        
        //preview form open/close & reset start
        previewGroup(listedGroup,i,field,e){

            if(field == 'input'){
            
                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){
                
                    if(listedGroup.id != -1){

                        this.formPreview(listedGroup,i);

                    }

                }

            }else{

                if(!this.tourIsActive){

                    if(listedGroup.id != -1){

                        this.formPreview(listedGroup,i);

                    }

                }

            }

        },
        formPreview(listedGroup,i){

            if(this.previewForm.id == -1){

                MessageBox.confirm(
                    "Are you sure? unsaved changes will be discarded", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(() => {

                    let index = this.groupsList.findIndex((group) => group.id == -1);
                    
                    if(index != -1){

                        this.groupsList.splice(index, 1);

                    }

                    if(!this.isGroupSaved && (this.tempPreviewGroup.id != listedGroup.id)){
                    
                        let tempIndex = this.groupsList.findIndex(x=>x.id == this.tempPreviewGroup.id);

                        if(tempIndex != -1){

                            this.groupsList[tempIndex] = _.cloneDeep(this.storedGroups[tempIndex]);

                        }

                    }

                    if(this.tempPreviewGroup.id != '' && this.tempPreviewGroup.id == listedGroup.id){
                    
                        let tempIndex = this.groupsList.findIndex(x=>x.id == this.tempPreviewGroup.id);

                        this.groupsList[tempIndex] = _.cloneDeep(this.storedGroups[tempIndex]);
                    
                        let groupedContacts = [];
                        
                        this.groupsList[tempIndex].contacts.forEach((attachedContact) =>{

                            groupedContacts.push(attachedContact.contact);

                        })

                        this.previewForm = this.groupsList[tempIndex];
                        this.previewForm.contacts = groupedContacts;
                        this.previewForm.index = i;

                        this.checkedItems = [];

                        this.groupIsSaving = false;

                        this.tempPreviewGroup = _.cloneDeep(this.groupsList[tempIndex]);

                        this.$nextTick((_) => {

                            if(this.$refs.groupObserver){

                                this.$refs.groupObserver.reset();

                            }

                        });

                    }else{

                        let group = listedGroup;

                        let groupedContacts = [];
                        
                        listedGroup.contacts.forEach((attachedContact) =>{

                            groupedContacts.push(attachedContact.contact);

                        })

                        this.previewForm = group;
                        this.previewForm.contacts = groupedContacts;
                        this.previewForm.index = i;

                        this.checkedItems = [];

                        this.groupIsSaving = false;

                        this.tempPreviewGroup = _.cloneDeep(group);

                        this.$nextTick((_) => {

                            if(this.$refs.groupObserver){

                                this.$refs.groupObserver.reset();

                            }

                        });

                    }

                }).catch(()=>{})

            }else{

                let index = this.groupsList.findIndex((group) => group.id == -1);
                
                if(index != -1){

                    this.groupsList.splice(index, 1);

                }

                if(!this.isGroupSaved && (this.tempPreviewGroup.id != listedGroup.id)){

                    let tempIndex = this.groupsList.findIndex(x=>x.id == this.tempPreviewGroup.id);

                    if(tempIndex != -1){

                        this.groupsList[tempIndex] = _.cloneDeep(this.storedGroups[tempIndex])

                    }

                }

                if(this.tempPreviewGroup.id != '' && this.tempPreviewGroup.id == listedGroup.id){
                    
                    let tempIndex = this.groupsList.findIndex(x=>x.id == this.tempPreviewGroup.id);

                    this.groupsList[tempIndex] = _.cloneDeep(this.storedGroups[tempIndex])

                    let groupedContacts = [];
                    
                    this.groupsList[tempIndex].contacts.forEach((attachedContact) =>{

                        groupedContacts.push(attachedContact.contact)

                    })

                    this.previewForm = this.groupsList[tempIndex]
                    this.previewForm.contacts = groupedContacts
                    this.previewForm.index = i

                    this.checkedItems = []

                    this.groupIsSaving = false;

                    this.tempPreviewGroup = _.cloneDeep(this.groupsList[tempIndex])

                    this.$nextTick((_) => {

                        if(this.$refs.groupObserver){

                            this.$refs.groupObserver.reset();

                        }

                    });

                }else{

                    let group = listedGroup

                    let groupedContacts = [];
                    
                    listedGroup.contacts.forEach((attachedContact) =>{

                        groupedContacts.push(attachedContact.contact)

                    })

                    this.previewForm = group
                    this.previewForm.contacts = groupedContacts
                    this.previewForm.index = i

                    this.checkedItems = []

                    this.groupIsSaving = false;

                    this.tempPreviewGroup = _.cloneDeep(group)

                    this.$nextTick((_) => {

                        if(this.$refs.groupObserver){

                            this.$refs.groupObserver.reset();

                        }

                    });

                }

            }

        },
        closePreview(){

            if(this.previewForm.isTouched){

                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async () => {

                    let index = null;

                    this.groupsList.forEach((group) => {

                        if(group.id == -1){

                            index = this.groupsList.indexOf(group);

                        }

                    });
                    if(index != null){

                        this.groupsList.splice(index, 1);

                    }


                    if(!this.isGroupSaved){

                        let tempIndex = this.groupsList.findIndex(x=>x.id == this.tempPreviewGroup.id);

                        if(tempIndex != -1){

                            this.groupsList[tempIndex] = _.cloneDeep(this.storedGroups[tempIndex])

                        }

                    }

                    this.previewForm.id = "";

                    // this.previewForm = {
                    //     id: '',
                    //     isPreview: false,
                    //     name: '',
                    //     description: '',
                    //     contacts:[],
                    //     isLoading: false,
                    //     isTouched:false,
                    // }

                    this.tempPreviewGroup = this.previewForm

                    this.isGroupSaved = false

                    this.groupIsSaving = false

                    this.$nextTick((_) => {

                        if(this.$refs.groupObserver){

                            this.$refs.groupObserver.reset();

                        }

                    });

                }).catch(()=>{})

            }else{

                let index = null;

                this.groupsList.forEach((group) => {
                    
                    if(group.id == -1){

                        index = this.groupsList.indexOf(group);

                    }

                });
                if(index != null){

                    this.groupsList.splice(index, 1);

                }

                if(!this.isGroupSaved){

                    let tempIndex = this.groupsList.findIndex(x=>x.id == this.tempPreviewGroup.id);

                    if(tempIndex != -1){

                        this.groupsList[tempIndex] = _.cloneDeep(this.storedGroups[tempIndex])

                    }

                }

                this.previewForm = {
                    id: '',
                    isPreview: false,
                    name: '',
                    description: '',
                    contacts:[],
                    isLoading: false,
                    isTouched:false,
                }

                this.tempPreviewGroup = this.previewForm

                this.isGroupSaved = false

                this.groupIsSaving = false

                this.$nextTick((_) => {

                    if(this.$refs.groupObserver){

                        this.$refs.groupObserver.reset();

                    }

                });

            }

        },
        resetPreview(){

            let index = null;

            this.groupsList.forEach((group) => {

                if (group.id == -1) {

                    index = this.groupsList.indexOf(group);

                }

            });

            if (index != null) {

                this.groupsList.splice(index, 1);

            }

            if(!this.isGroupSaved){

                let tempIndex = this.groupsList.findIndex(x=>x.id == this.tempPreviewGroup.id);

                if(tempIndex != -1){

                    this.groupsList[tempIndex] = _.cloneDeep(this.storedGroups[tempIndex])

                }

            }

            this.previewForm = {
                id: '',
                isPreview: false,
                name: '',
                description: '',
                contacts:[],
                isLoading: false,
                isTouched:false,
            }

            this.tempCustomer = this.previewForm

            this.isGroupSaved = false

            this.groupIsSaving = false

            this.$nextTick((_) => {

                if(this.$refs.groupObserver){

                    this.$refs.groupObserver.reset();

                }

            });

        },
        createGroup(){

            if(this.groupsLoading){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "Groups are being retrieved.",
                });

                return

            }
            
            if(this.groupIsSaving){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "A group is currently being processed.",
                });

                return

            }

            let index = this.groupsList.findIndex(x=>x.id == -1);

            if(index != -1){
                
                // this.$notify({
                //   title: "Can't Add New Group",
                //   type: "error",
                //   message: "you have an unsaved group in the list",
                // });

                return
            }

            this.previewForm = {
                id: -1,
                isPreview: false,
                name: '',
                description: '',
                contacts:[],
                isLoading: false,
                isTouched:false,
            }

            let tempGroup = this.previewForm

            this.groupsList.unshift(tempGroup);

            this.$nextTick((_) => {

                if(this.$refs.groupObserver){

                    this.$refs.groupObserver.reset();

                }

            });

            this.checkedItems = []

        },
        //preview form open/close & reset end

        //validate add/update form start
        async validate(form){

            if(form == 'preview'){

                this.$refs.groupObserver.validate().then(success => {

                    if(!success){

                        const errors = Object.entries(this.$refs.groupObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                        this.$refs.groupObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                    }else{

                        if(this.previewForm.contacts.length == 0){
                            
                            this.$notify({
                                type: 'info',
                                title: 'Contacts Missing',
                                message: "At least one contact must be added to the group.",
                            });

                            return

                        }else{

                            if(this.previewForm.id == -1){

                                this.addNewGroupHandle();

                            }else{

                                this.updateGroupHandle();

                            }

                        }

                    }

                });

            }

        },
        //validate add/update form end

        //groups add/update methods start
        async addNewGroupHandle(){

            let formData = new FormData();

            formData.append('name',this.previewForm.name.replace(/\s+/g,' ').trim());

            if(this.previewForm.description){

                formData.append('description',this.previewForm.description.replace(/\s+/g,' ').trim());

            }

            let formContacts = [];

            this.previewForm.contacts.forEach((contact) =>{

                formContacts.push(contact.id)

            });

            formData.append('contacts',JSON.stringify(formContacts));

            this.groupIsSaving = true
            try{

                let res = await this.$axios.post('/crm/group/add',formData);
                if(res.data.status_code == "1441"){
                    
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.isGroupSaved = true;

                    this.resetPreview();

                    this.$store.commit('groups_module/add_groups_list',res.data.group);

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.name){
                        
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.name[0],
                        });

                    }else if(error.response.data.error.includes("deleted or isn't available")){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.groupIsSaving = false

            }

        },
        async updateGroupHandle(){

            let formData = new FormData();

            formData.append('group_id',this.previewForm.id);
            formData.append('name',this.previewForm.name.replace(/\s+/g,' ').trim());

            if(this.previewForm.description){

                formData.append('description',this.previewForm.description.replace(/\s+/g,' ').trim());

            }

            let formContacts = [];

            this.previewForm.contacts.forEach((contact) =>{

                formContacts.push(contact.id)

            });

            formData.append('contacts',JSON.stringify(formContacts));

            this.groupIsSaving = true
            try{

                let res = await this.$axios.post('/crm/group/update',formData);
                if(res.data.status_code == "1442"){
                    
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    let groupIndex = this.groupsList.findIndex((group) => group.id == res.data.group.id);

                    this.groupsList[groupIndex] = _.cloneDeep(res.data.group)

                    let payload = {
                        index: groupIndex,
                        updatedGroup: res.data.group
                    }

                    this.$store.commit('groups_module/update_groups_list',payload);

                    if(this.previewForm.id == res.data.group.id){
                        
                        this.isGroupSaved = true;

                        this.resetPreview();

                    }


                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.group_id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.group_id[0],
                        });

                    }else if(error.response.data.error.name){
                        
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.name[0],
                        });

                    }else if(error.response.data.error.includes("deleted or isn't available.")){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.groupIsSaving = false

            }

        },
        async removeGroupHandle(listedGroup,i){

            MessageBox.confirm(
                'Are you sure you want to delete the group?',
                'Warning',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(async ()=>{

                this.deletingItems.push(listedGroup.id);
                this.deleteLoader = true;

                let loader = Loading.service({
                    text: "The group is being removed. Please wait!",
                    fullscreen: true,
                });

                try{
                    let res = await this.$axios.delete(`/crm/group/remove/${listedGroup.id}`);
                    if(res.data.status_code == "1443"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        // this.$store.commit('groups_module/delete_groups_list',i);
                        
                        //removing deleted item from checked items list
                        this.checkedItems = this.checkedItems.filter((item) => {

                            if(item != listedGroup.id){
                                
                                return item;
                                
                            }

                        });

                        this.groupsLoading = true;

                        this.$store.dispatch('groups_module/fetchStoreGroupsRequest',{ size: 15 ,page: 1, loadData:'new' });

                    }
                }catch(error){

                    if(error.response){

                        if(error.response.data.error.group_id){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.group_id[0],
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            }); 

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                    this.deleteLoader = false;
                    this.deletingItems = [];

                }

            }).catch(()=>{})

        },
        //groups add/update methods end

        //contacts start
        openContactsModal(){

            this.contactsModal.selectedContacts = [];

            this.contactsModal.isOpen = true;

            this.contactsModal.selectedContacts = _.cloneDeep(this.previewForm.contacts);

        },
        closeContactsModal(){

            this.contactsModal.isOpen = false;

            this.previewForm.contacts = _.cloneDeep(this.contactsModal.selectedContacts);

            if((this.searchedContact.length != 0) || (this.contactsPagination.current_page != 1) ||
            (this.selectedContactFilter != 'all')){

                this.searchedContact = '';

                this.selectedContactFilter = 'all';

                this.searchContacts('simple',1);

            }

        },
        removeFormContactHandle(listedContact,index){

            MessageBox.confirm(
                'Are you sure you want to remove the contact?',
                'Warning',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(async ()=>{

                this.previewForm.contacts.splice(index,1);

                this.$notify({
                    type: 'success',
                    title: 'Success',
                    message: "Contact has been successfully removed.",
                });

            }).catch(() => {})

        },
        openGroupedContactsModal(group,index){

            this.groupedContactsModal.contacts = [];

            let tempContacts = [];
            
            group.contacts.forEach((groupedContact)=>{

                tempContacts.push(groupedContact.contact);

            })

            this.groupedContactsModal.contacts = _.cloneDeep(tempContacts);

            this.groupedContactsModal.isOpen = true;

        },
        toggleIsCheckedAllGroupedContacts(){

            if(!this.tourIsActive){

                if(this.contactsModal.selectedContacts.length == this.contactsModal.list.length){

                    this.contactsModal.selectedContacts = [];

                }else if(this.contactsModal.selectedContacts.length < this.contactsModal.list.length){
                    
                    this.contactsModal.selectedContacts = [];

                    this.contactsModal.list.forEach((contact) => {

                        this.contactsModal.selectedContacts.push(contact);

                    });
                    
                }

            }

        },

        contactsPaginateChangeHandle(currentPage, pageSize){

            this.searchContacts('simple',currentPage);

        },
        async searchContacts(operation, page){

            if(operation == 'simple'){

                this.loadContacts(page, this.searchedContact);

            }else if(operation == 'search'){

                this.contactsSearchLoader = true;

                clearTimeout(this.timer);

                this.timer = setTimeout( async () => {

                    this.loadContacts(page, this.searchedContact);

                }, 300);

            }

        },
        async loadContacts(page, keyword){

            this.contactsSearchLoader = true;
            try{
                let res = await this.$axios.get('/crm/contact/all',{
                    params:{
                        search: keyword.trim() ? keyword.trim() : null,
                        offset: 10,
                        page: page,
                        filter: this.selectedContactFilter ? this.selectedContactFilter : null,
                    },
                });
                if(res.data.status_code == "1460"){
                    
                    this.contactsModal.list = _.cloneDeep(res.data.contacts.data);

                    this.contactsPagination = {
                        current_page: page,
                        total: res.data.contacts.last_page,
                        previous_page:0,
                        last_page: res.data.contacts.last_page
                    }

                }
            }catch(error){

                this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });
                
            }finally{

                this.contactsSearchLoader = false;

            }

        },

        generateContactImageSrc(contact){
            
            if(contact.image){

                if(contact.is_own_image == 0){

                    if(typeof(contact.image) == "string"){

                        return this.base_image_url + this.settings.store_id + '/user/' + contact.image;

                    }else{

                        return contact.image_src;

                    }

                }else{

                    if(typeof(contact.image) == "string"){

                        return this.base_image_url + this.settings.store_id + '/crm-contact/' + contact.image;

                    }else{

                        return contact.image_src;

                    }

                }

            }else if(contact.image_src){

                if(contact.is_own_image == 1){
                    
                    return this.base_image_url + this.settings.store_id + '/crm-contact/' + contact.image_src;
                    
                }else{

                    return this.base_image_url + this.settings.store_id + '/user/' + contact.image_src;

                }

            }

        },
        contactsToggleIsCheckedAll(){

            const uniqueInContactLists = this.contactsModal.list.filter(obj1 => 
                !this.contactsModal.selectedContacts.some(obj2 => obj2.id === obj1.id)
            );

            if(uniqueInContactLists.length > 0){

                this.contactsModal.selectedContacts.push(...uniqueInContactLists);

            }else{

                const uniqueInSelectedContacts = this.contactsModal.selectedContacts.filter(obj1 => 
                    !this.contactsModal.list.some(obj2 => obj2.id === obj1.id)
                );

                this.contactsModal.selectedContacts = _.cloneDeep(uniqueInSelectedContacts);

            }

        },
        checkSelectedContacts(){

            const uniqueInContactLists = this.contactsModal.list.filter(obj1 => 
                !this.contactsModal.selectedContacts.some(obj2 => obj2.id === obj1.id)
            );

            if((uniqueInContactLists.length == 0) || (this.contactsModal.list.length == 0)){

                return true;

            }else{

                return false

            }

        },
        //contacts end

        //check/uncheck & bulk action start
        checkedItemHandle(groupId){
            
            if(!this.tourIsActive && groupId != -1 && !this.previewForm.id){
                
                let index = this.checkedItems.findIndex(item => item == groupId);
                
                if(index != -1){

                    this.checkedItems.splice(index,1);

                }else{

                    this.checkedItems.push(groupId);

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive){

                if(this.checkedItems.length == this.groupsList.length){
                    
                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.groupsList.length){
                    
                    this.checkedItems = [];

                    this.groupsList.forEach((group) => {

                        this.checkedItems.push(group.id);

                    });
                    
                }

            }

        },
        clearSelection(){
            
            MessageBox.confirm(
                'Do you really want to uncheck the selected records?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async () => {

                this.checkedItems = [];

            }).catch(() => {})

        },
        async deleteBulkGroups(){

            this.resetPreview()

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                let formData = new FormData();

                formData.append('resource_type', 'crm_group');
                formData.append("resource_ids", JSON.stringify(this.checkedItems));
                formData.append('action','delete');

                this.deletingItems = _.cloneDeep(this.checkedItems);
                this.deleteLoader = true;

                let loader = Loading.service({
                    text: "The selected groups are being removed. Please wait!",
                    fullscreen: true,
                });

                try{
                    let res = await this.$axios.post('/bulk-action',formData);
                    if(res.data.status_code == "2031"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: "The CRM groups have been successfully removed.",
                        });

                        // let payload = {
                        //     listIds: this.checkedItems
                        // }

                        // this.$store.commit('groups_module/groups_bulk_action',payload);

                        this.checkedItems = [];
                        
                        this.groupsLoading = true;

                        this.$store.dispatch('groups_module/fetchStoreGroupsRequest',{ size: 15 ,page: 1, loadData:'new' });

                    }
                }catch(error){

                    if(error.response){

                        if(error.response.data.message){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: "One of the groups may have been deleted or isn't available.",
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                    this.deletingItems = [];
                    this.deleteLoader = false;

                }

            }).catch(()=>{})

        },
        //check/uncheck & bulk action end

        //group searching start
        searchGroup(keyword){

            this.$refs.module_search.searchedKeyword = keyword
            
            this.$refs.module_search.search();

        },
        //group searching end

        //tour methods start
        startGroupsTour(){

            this.$tours.groupsTour.start();

            this.tourIsActive = true

            if(this.previewForm.id != ''){

                this.resetPreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                this.createGroup();

                this.$nextTick((_) => {

                    document.querySelector(".tableActionBtns").style.position = "static";

                    document.querySelector(".productSlideBox").style.overflowY  = "hidden";

                });

            }

            if(currentStep + 1 == 15){
                
                this.expand();

            }

            if(currentStep + 1 == 16){
                
                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){
                
                    this.expand();
                
                }

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 7){

                this.resetPreview();

                document.querySelector(".tableActionBtns").style.position = "sticky";

                document.querySelector(".productSlideBox").style.overflowY  = "auto";

            }

            if(currentStep == 15){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep == 16){

                this.expand();

            }

        },
        handleTourStop(){

            if(this.previewForm.id != ''){

                document.querySelector(".tableActionBtns").style.position = "sticky";

                document.querySelector(".productSlideBox").style.overflowY  = "auto";

            }

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourFinish(){

            if(this.previewForm.id != ''){

                document.querySelector(".tableActionBtns").style.position = "sticky";

                document.querySelector(".productSlideBox").style.overflowY  = "auto";

            }

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            if(this.previewForm.id != ''){

                document.querySelector(".tableActionBtns").style.position = "sticky";

                document.querySelector(".productSlideBox").style.overflowY  = "auto";

            }

            this.resetPreview();

            this.tourIsActive = false;

        },
        //tour methods end

        //other methods start
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        groupsKeyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }
                
            }

        },

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },


        //fetch module data if needed
        fetchModuleData(){

            if((!this.storedGroups || this.storedGroups.length == 0) && this.groupsSearchKeyword.length == 0){

                this.groupsLoading = true;
                try{

                    this.$store.dispatch('groups_module/fetchStoreGroupsRequest',{ size: 15 ,page: 1, loadData:'new' });

                }catch(error){

                    this.groupsLoading = false;

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message,
                    });

                }

            }else{

                this.groupsList = _.cloneDeep(this.storedGroups);

            }

            this.searchContacts('simple',1);

        },

    },
    mounted(){

        //fetching module data
        this.fetchModuleData();

        this.$refs.groupsTable.addEventListener('scroll', this.handleScrollEvent)

        window.addEventListener('keydown',this.groupsKeyEvent)

        window.addEventListener('beforeunload', (event) => {

            let index = this.groupsList.findIndex((group) => group.id == -1);

            if(this.previewForm.id == -1 || index != -1){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        if(this.$route.query.search){
        
            this.$refs.module_search.searchedKeyword = this.$route.query.search

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true

            }

            this.$router.replace({'query': null});

            this.$refs.module_search.search();

        }

    },
    beforeMount(){

    },
    beforeDestroy(){
        
        window.removeEventListener("keydown", this.groupsKeyEvent);

    },
    beforeRouteLeave(to, from, next) {

        let index = null;

        this.groupsList.forEach((contact) => {

            if(contact.id == -1){

                index = this.groupsList.indexOf(contact);

            }

        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{

                this.resetPreview();

                next();

            }).catch(()=>{})

        }else{

            next()

        }

    },


}
</script>

<style scoped>

    /* Transition Effects */
    .record-form-enter-active, 
    .record-form-leave-active {
        transition: all 0.3s ease-in-out;
    }

    /* Initial state when entering */
    .record-form-enter {
        opacity: 0;
        right: -100%;
        visibility: hidden;
    }

    /* Final state when leaving */
    .record-form-leave-to {
        opacity: 0;
        right: -100%;
        visibility: hidden;
    }


    /* Transition Effects */
    .modal-enter-active, 
    .modal-leave-active {
        transition: all 0.3s ease-in-out;
    }

    /* Initial state when entering */
    .modal-enter{
        opacity: 0;
        visibility: hidden;
    }

    /* Final state when leaving */
    .modal-leave-to {
        opacity: 0;
        visibility: hidden;
    }


</style>